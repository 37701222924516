@import-normalize;

@import "csem-variables.scss";
@import "terminal.scss";

.form-floating > button.form-control {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

html {
  height: 100%;
}
body {
  min-height: 100%;
}

#root {
  min-height: 100vh;
}

.vh-100 {
  min-height: 100vh;
}

a {
  cursor: pointer;
}

.render_lines {
  white-space: pre-wrap;
}

.csem-table-filter {
  width: 350px;
}

#xem-main {
  background-color: $csem-bg-color;
  padding-bottom: 400px;
}

#xem-menu {
  color: $csem-menu-item-normal;
  background-color: $csem-blue;
  align-items: start;

  .navbar-brand {
    margin-left: 1rem;
    padding: 0px;
  }

  #xem-menu-content {
    align-items: flex-start;
    width: 100%;
  }

  .form-floating {
    color: $csem-text-grey;

    select {
      background-color: $csem-blue;
      color: $csem-text-grey;
      color: transparent;
      -webkit-text-fill-color: white;

      option {
        color: $csem-text-grey;
      }
    }
  }

  // First level
  > div > div > ul > li {
    line-height: $csem-menu-item-height;
    height: $csem-menu-item-height;
    font-size: 1.1em;

    a {
      color: $csem-text-grey;

      &.active {
        font-weight: bold;
        background-color: adjust-color(lighten($csem-blue, 10%), $saturation: 0%);
      }
    }
  }

  // Second level
  > div > div > ul > ul {
    > li > a {
      padding-left: 30px;

      background-color: lighten($csem-blue, 40%);
      color: $csem-blue;

      &.active {
        font-weight: bold;
        background-color: adjust-color(lighten($csem-blue, 45%), $saturation: -10%);
      }
    }

    font-size: 0.9em;

    > li {
      line-height: $csem-menu-item-height - 15px;
      height: $csem-menu-item-height - 15px;
    }
  }

  // Third level
  > div > div > ul > ul > ul {
    > li > a {
      padding-left: 35px;

      background-color: lighten($csem-blue, 60%);
      color: $csem-blue;

      &.active {
        font-weight: bold;
        background-color: adjust-color(lighten($csem-blue, 65%), $saturation: -10%);
      }
    }

    font-size: 1em;

    > li {
      line-height: $csem-menu-item-height - 20px;
      height: $csem-menu-item-height - 20px;
    }
  }

  // Fourth level
  > div > div > ul > ul > ul > ul {
    > li > a {
      padding-left: 40px;

      background-color: lighten($csem-blue, 70%);
      color: $csem-blue;

      &.active {
        font-weight: bold;
        background-color: adjust-color(lighten($csem-blue, 85%), $saturation: -20%);
      }
    }

    font-size: 1em;

    > li {
      line-height: $csem-menu-item-height - 22px;
      height: $csem-menu-item-height - 22px;
    }
  }

  // Fifth level
  > div > div > ul > ul > ul > ul > ul {
    > li > a {
      padding-left: 60px;
      background-color: white;
      color: $csem-blue;
      &.active {
        font-weight: bold;
        background-color: adjust-color(lighten($csem-blue, 85%), $saturation: -20%);
      }
    }

    font-size: 0.9em;

    > li {
      a:before {
        content: "- ";
      }
      line-height: $csem-menu-item-height - 22px;
      height: $csem-menu-item-height - 22px;
    }
  }

  ul {
    padding: 0px;

    li {
      list-style-type: none;
      width: 100%;
      text-indent: 0px;
    }

    .title,
    a {
      padding-left: 20px;
    }
  }

  li a {
    width: 100%;
    display: block;
    position: relative;

    text-decoration: none;
  }

  > div > div > ul > li > a {
    &.active {
      &:after {
        width: 0;
        height: 0;
        border-top: $csem-menu-triangle-height / 2 solid transparent;
        border-right: $csem-menu-triangle-height solid $csem-bg-color;
        border-bottom: $csem-menu-triangle-height / 2 solid transparent;
        content: "";
        margin-top: ($csem-menu-item-height - $csem-menu-triangle-height) / 2;
        position: absolute;
        right: 0px;
      }
    }
  }
}

.welcome {
  margin-top: 20%;

  .text {
    font-size: 200px;
    color: $csem-blue;
    text-align: center;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in;

  &.delayed {
    &.delayed-first {
      animation-delay: 2.5s;
      -webkit-animation-delay: 2.5s;
    }

    &.delayed-second {
      animation-delay: 3.5s;
      -webkit-animation-delay: 3.5s;
    }

    opacity: 0%;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@import "form-switch.scss";
@import "bootstrap-variables.scss";
// Import Bootstrap and its default variables
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap/scss/bootstrap.scss";

@import "csem-cards.scss";

@include media-breakpoint-up(sm) {
  @extend .h-100;
}

#xem-menu {
  img.logo {
    width: 100px;
  }
}

@include media-breakpoint-up(sm) {
  #xem-menu {
    min-height: 100%;
    flex-direction: column;

    img.logo {
      margin: 10%;
      max-height: 40px;
      max-width: 80%;
      width: auto;
    }
  }
}

.csem-info::before {
  content: "\f431";
  font-size: 3rem;

  display: block;
  float: left;
  margin-right: 10px;

  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-as-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
}

.csem-table > * > * > * {
  border-width: $border-width $border-width $border-width $border-width;
}

.csem-avatar-wrapper {
  content: "dsf";
  background-color: $secondary;
  width: 30px;
  height: 30px;
  padding-left: 2px;
  padding-top: 2px;
  display: block;

  @extend .rounded-circle;
}

.cursor-pointer {
  cursor: pointer;
}

.scan:hover {
  background-color: lighten($secondary, 50%);
}

.list-group-overflow {
  max-height: 400px;
  overflow-y: scroll;
}

.checkbox-lg {
  width: 30px;
  height: 30px;
}

.csem-login {
  .card-body {
    @extend .bg-light;
    &:hover {
      @extend .bg-opacity-25;
      @extend .bg-secondary;
    }
    font-size: 2em;
  }
}
.popover {
  max-width: 600px;
}


$light: ("0": 0, "10": 90%, "25": 75%, "50": 50%, "75": 25%, "100": 0 );

@each $color, $value in $theme-colors {
  @each $color2, $value2 in $theme-colors {
    .bg-dbl-#{$color}-#{$color2} {
      //--bs-bg-opacity: 1;

      $a: rgba($value, var(--bs-bg-opacity));
      $b: rgba($value2, var(--bs-bg-opacity));
      background: repeating-linear-gradient(45deg, #{$a}, #{$a} 45px, #{$b} 45px, #{$b} 90px);

      --bs-bg-opacity: 100
    }

    @each $key, $lightValue in $light {
      .bg-dbl-#{$color}-#{$color2}-#{$key} {
        //--bs-bg-opacity: 1;

        $a: tint-color( $value, $lightValue );
        $b: tint-color( $value2, $lightValue );
        background: repeating-linear-gradient(45deg, #{$a}, #{$a} 45px, #{$b} 45px, #{$b} 90px);
      }
    }  
  }
}

@each $color, $value in $theme-colors {
  @each $key, $lightValue in $light {
    .bg-#{$color}-#{$key} {
      //--bs-bg-opacity: 1;
      background-color: tint-color( $value, $lightValue )
    }
  }
}

.csem-order-progress-wrapper {
  z-index: -1;
  position: relative;
}

.csem-progress {
  position: relative;
}

.csem-progress-steps {
position: relative;
z-index: 10;
}

.csem-progress-vbar {
 position: relative;
 top: 40px;
    
 .col {
  height: 12px;
  
  width: 100%;
  z-index: 0;
 // border: 2px solid red;
  @extend .bg-primary;
  @extend .bg-opacity-25;
  
 }

 .col.active {
  @extend .bg-opacity-100;

 }

 .col:first-child, .col:last-child {
 --bs-bg-opacity: 0;
 }
 

}

.csem-order-progress:before {
  position: absolute;
  height: 10px;
  width: 200%;
  margin-left: -50%;
  background-color: $primary;
  margin-top: calc( 50% );
  z-index: -1;  
  content: " ";
}