$csem-blue: #005c83;
$csem-text-grey: #dedede;
$csem-menu-item-height: 50px;
$csem-menu-triangle-height: 20px;
$csem-menu-item-highlighted-bg: lighten($csem-blue, 5%);
$csem-menu-item-normal: $csem-text-grey;
$csem-menu-item-highlighted: $csem-text-grey;
$csem-bg-color: #f0f5fc;

$xem-value-color: #555555;

$xem-card-text-scaling-value: 3rem;
$xem-card-text-scaling-unit: 1.5rem;
$xem-card-text-scaling-quality: 1rem;
$xem-graph-height: 400px;

$font-size-xs: 0.5rem;