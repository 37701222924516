.csem-badge {
  $size: 46px;
  $padding: 10px;
  display: inline-block;
  border-radius: $size / 2;
  padding: $padding;
  height: $size;

  > div:first-child {
    line-height: $size - $padding * 2;

    float: left;
    margin-right: 10px;
    font-size: 2rem;

    background-color: white;
    border-radius: 50%;
    padding: 5px;
    margin-top: -$padding + 2px;
    margin-left: -$padding + 2px;
    height: $size - 4px;
    width: $size - 4px;
  }

  > div:nth-child(2) {
    // margin-top: -$padding + 2px;
  }
}
