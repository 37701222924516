
.xem-card-graph {
 /*   height: $xem-graph-height;*/
}

.xem-card-streaming {

    .card-text {
        width: 100%;
        text-align: center;
    }

    .xem-card-value {
        color: $xem-value-color;
        font-size: $xem-card-text-scaling-value;
    }
    
    .xem-card-value-sm {
        color: $xem-value-color;
        font-size: $xem-card-text-scaling-value * 0.7;
    }
    
    .xem-card-value-xs {
        color: $xem-value-color;
        font-size: $xem-card-text-scaling-value * 0.4;
    }

    .xem-card-unit {
        text-align: center;
        color: $xem-value-color;
        font-size: $xem-card-text-scaling-unit;
    }

    .xem-card-qvalue {
        font-size: $xem-card-text-scaling-quality;
    }
    .card-footer {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

