@import "csem-variables.scss";

// scss-docs-start theme-color-variables
$primary:       $csem-blue;

$success:       #3d6637;
$info:          #2d6f73;
$warning:       #f2c968;
$danger:        #b36360;

$enable-rounded: false;

$font-size-base: 0.9rem;